'use strict'

import 'cleave.js/dist/cleave.min.js'
import 'cleave.js/dist/addons/cleave-phone.us.js'

function onFormSubmit (token) {
// document.querySelector('#contact-form').addEventListener('submit', function (e) {  
  //e.preventDefault()
  let form = document.getElementById('contact-form')
  let data = new FormData(form) // create new FormData object
  data = data.entries()
  let obj = data.next() // access iterator
  const retrieved = {}
  while (undefined !== obj.value) {    
    retrieved[obj.value[0]] = obj.value[1]
    obj = data.next()
  } // parse FormData into simple object

  document.getElementById('contact-form-success').style.display = 'none'
  document.getElementById('contact-form-error').style.display = 'none'

  let warnings = document.querySelectorAll('#contact-form .form-error')
  Array.prototype.forEach.call(warnings, function (el, i) {
    el.style.display = 'none'
  })

  let fields = document.querySelectorAll('#contact-form input, #contact-form textarea')
  Array.prototype.forEach.call(fields, function (el, i) {
    el.classList.remove('is-danger')
  })

  if (retrieved.name && retrieved.phone && retrieved.email && retrieved.subject && retrieved.request && token) {
    let XHR = new XMLHttpRequest()

    retrieved.captcha = retrieved['g-recaptcha-response']
    delete retrieved['g-recaptcha-response']

    XHR.addEventListener('load', function (event) {
      if (event.target.status === 200) {
        document.getElementById('contact-form').style.display = 'none'
        document.getElementById('contact-form-success').style.display = 'block'
        document.getElementById('contact-form').reset()
        if (window.grecaptcha) {
          grecaptcha.reset()
        }
      } else {
        document.getElementById('contact-form-error').style.display = 'block'
      }
    })

    XHR.addEventListener('error', function (event) {
      document.getElementById('contact-form-error').style.display = 'block'
    })

    XHR.open('POST', process.env.API_URL + '/api/public/stuff/contactUs')
    XHR.setRequestHeader('Content-Type', 'application/json')
    XHR.setRequestHeader('X-Accolend-Env', process.env.NODE_ENV)
    XHR.send(JSON.stringify(retrieved))
  } else {
    if (!retrieved.name) {
      document.querySelector('#contact-form input[name="name"]').classList.add('is-danger')
      document.getElementById('contact-form-error-name').style.display = 'block'
    }
    if (!retrieved.phone) {
      document.querySelector('#contact-form input[name="phone"]').classList.add('is-danger')
      document.getElementById('contact-form-error-phone').style.display = 'block'
    }
    if (!retrieved.email) {
      document.querySelector('#contact-form input[name="email"]').classList.add('is-danger')
      document.getElementById('contact-form-error-email').style.display = 'block'
    }
    if (!retrieved.subject) {
      document.querySelector('#contact-form input[name="subject"]').classList.add('is-danger')
      document.getElementById('contact-form-error-subject').style.display = 'block'
    }
    if (!retrieved.request) {
      document.querySelector('#contact-form textarea[name="request"]').classList.add('is-danger')
      document.getElementById('contact-form-error-request').style.display = 'block'
    }
  }
}

if (document.querySelector('.input-phone') !== null) {
  let cleave = new Cleave('.input-phone', {
    phone: true,
    phoneRegionCode: 'us',
    delimiter: '-'
  })
}

window.onContactFormSubmit = onFormSubmit
